import React, { Component } from "react";
import StellaRosaLogo from "./Images/stellarossa_logo.png";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "./Footer";

export default class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      recaptcha: false,
      success: ""
    };
  }

  email(e) {
    this.setState({
      email: e.target.value
    });
  }

  async resetPassword() {
    this.setState({
      success: "working"
    });
    await fetch(
      process.env.REACT_APP_BACKEND_HOST + `/1.0/auth/admin/password-reset`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        email: this.state.email
      })
    })
      .then(x => x.json())
      .then(resp => {
        console.log(resp);
        this.setState({
          success: true
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          success: false
        });
      });
  }

  recaptcha(e) {
    if (e !== null) {
      this.setState({
        recaptcha: true
      });
    } else {
      this.setState({
        recaptcha: false
      });
    }
  }

  render() {
    return (
      <div style={{ height: "100vh" }}>
        <div className="container">
          <div className="row" style={{ paddingTop: "15vh" }}>
            <div className="col-lg-2" />
            <div
              className="col-lg-8 pb-2"
              style={{
                textAlign: "center",
                borderRadius: "6px",
                boxShadow: "0px 0px 14px -5px rgba(0,0,0,0.41)"
              }}
            >
              <div
                className="row"
                style={{ borderBottom: "6px solid #be9e55" }}
              >
                <div
                  className="col-lg-12 py-5"
                  style={{
                    backgroundColor: "black"
                  }}
                >
                  <img
                    src={StellaRosaLogo}
                    alt="Stella-Rosa Logo"
                    width={400}
                    style={{ textAlign: "center" }}
                  />
                </div>
              </div>
              <div
                className="row pt-3 pb-4 mt-4 mb-1"
                style={{ backgroundColor: "white" }}
              >
                <div className="col-lg-1" />
                <div className="col-lg-10 my-3">
                  <p style={{ fontSize: 22 }}>
                    Enter the email address you used when creating the account
                    and click Send.
                  </p>
                </div>
                <div className="col-lg-1" />
                <div className="col-lg-12 mt-4">
                  <div className="row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6">
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={this.state.email}
                        onChange={e => this.email(e)}
                        style={{
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                          width: "100%"
                        }}
                        className="py-2"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3" />
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-3" />
                  <div
                    className="col-lg-4 pl-4"
                    style={{ textAlign: "center" }}
                  >
                    <ReCAPTCHA
                      sitekey="6Lf9M7IUAAAAAFQq024Cc9jbks3GSkWW9UDWcv7_"
                      onChange={e => this.recaptcha(e)}
                    />
                  </div>
                  <div className="col-lg-5" />
                </div>
              </div>

              <div className="row my-4">
                <div className="col-lg-12">
                  <button
                    type="button"
                    className="btn px-5 py-3 rounded-pill"
                    style={
                      this.state.email.length > 0 &&
                        this.state.recaptcha !== false
                        ? { backgroundColor: "#c10230", color: "white" }
                        : {
                          backgroundColor: "#c4c5d6",
                          color: "white"
                        }
                    }
                    disabled={
                      this.state.email.length > 0 &&
                        this.state.recaptcha !== false
                        ? false
                        : true
                    }
                    onClick={() => this.resetPassword()}
                  >
                    {" "}
                    Reset Password
                  </button>
                </div>
              </div>
              {this.state.success === true ? (
                <div className="row my-4">
                  <div className="col-lg-12">
                    <div className="alert alert-success">
                      Reseted the password, check the email for instructions
                    </div>
                  </div>
                </div>
              ) : null}
              {this.state.success === false ? (
                <div className="row my-4">
                  <div className="col-lg-12">
                    <div className="alert alert-danger">
                      Failed to send email, check your typed email and try again
                    </div>
                  </div>
                </div>
              ) : null}
              {this.state.success === "working" ? (
                <div className="row my-4">
                  <div className="col-lg-12">
                    <div className="alert alert-light">Sending mail...</div>
                  </div>
                </div>
              ) : null}
              <div className="row my-2">
                <div className="col-lg-12">
                  <Link to="/" style={{ color: "#be9e55" }}>
                    Back
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-2" />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
