import React, { Component } from "react";
import { Switch, Route } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import Login from "./Login.jsx";
import PasswordReset from "./PasswordReset.jsx";
import Panel from "./Panel.jsx";
import PasswordSet from "./PasswordSet.jsx";
import ResetSuccess from "./ResetSuccess.jsx";

export default class app extends Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route path="/" component={Login} exact />
            <Route path="/resetpassword" component={PasswordReset} exact />
            <Route path="/panel" component={Panel} exact />
            <Route path="/newpassword" component={PasswordSet} exact />
            <Route path="/resetsuccess" component={ResetSuccess} exact />
          </Switch>
        </Router>
      </div>
    );
  }
}
