import React, { Component } from "react";
import StellaRosaLogo from "./Images/stellarossa_logo.png";
import { Link, Redirect } from "react-router-dom";
import Footer from "./Footer";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      logged: null
    };
  }

  login(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  async clickedLogIn() {
    await fetch(
      process.env.REACT_APP_BACKEND_HOST + `/1.0/auth/users/login`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password
      })
    })
      .then(x => x.json())
      .then(resp => {
        this.setState({
          logged: true,
          token: resp.access_token,
          uid: resp.uid
        });
      })
      .catch(() => {
        this.setState({
          logged: false
        });
      });
  }

  render() {
    return (
      <div style={{ height: "100vh" }}>
        <div className="container">
          <div className="row" style={{ paddingTop: "15vh" }}>
            <div className="col-lg-2" />
            <div
              className="col-lg-8 pb-2"
              style={{
                textAlign: "center",
                borderRadius: "6px",
                boxShadow: "0px 0px 14px -5px rgba(0,0,0,0.41)"
              }}
            >
              <div
                className="row"
                style={{ borderBottom: "6px solid #be9e55" }}
              >
                <div
                  className="col-lg-12 py-5"
                  style={{
                    backgroundColor: "black"
                  }}
                >
                  <img
                    src={StellaRosaLogo}
                    alt="Stella-Rosa Logo"
                    width={400}
                    style={{ textAlign: "center" }}
                  />
                </div>
              </div>
              <div
                className="row pt-3 pb-4 mt-4 mb-1"
                style={{ backgroundColor: "white" }}
              >
                <div className="col-lg-12 my-3">
                  <p style={{ fontSize: 22 }}>Login To Your Account</p>
                </div>
                <div className="col-lg-12 mt-4">
                  <div className="row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6">
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={this.state.email}
                        onChange={e => this.login(e)}
                        style={{
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          width: "100%"
                        }}
                        className="py-2"
                      />
                    </div>
                    <div className="col-lg-3" />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6 pt-4">
                      <input
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={this.state.password}
                        onChange={e => this.login(e)}
                        style={{ border: "none", width: "100%" }}
                        className="py-2"
                      />
                    </div>
                    <div className="col-lg-3" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row mt-3">
                  <div className="col-lg-3" />
                  <div className="col-lg-3">
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="RememberMe"
                      id=""
                    />
                    <span>Remember me</span>
                  </div>
                  <div className="col-lg-3">
                    <Link to="/resetpassword" style={{ color: "#be9e35" }}>
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="col-lg-3" />
                </div>
              </div>
              <div className="row my-5">
                <div className="col-lg-12" style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn px-5 py-3 rounded-pill"
                    style={
                      this.state.email.length > 0 &&
                        this.state.password.length > 0
                        ? { backgroundColor: "#c10230", color: "white" }
                        : { backgroundColor: "#c4c5d6", color: "white" }
                    }
                    disabled={
                      this.state.email.length > 0 && this.state.password.length
                        ? false
                        : true
                    }
                    onClick={() => this.clickedLogIn()}
                  >
                    Log in
                  </button>
                </div>
              </div>
              {this.state.logged === false ? (
                <div className="row my-1">
                  <div className="col-lg-12">
                    <div className="alert alert-danger">
                      Wrong email or password, check and try again
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-lg-2" />
          </div>
        </div>
        {this.state.logged === true ? (
          <Redirect
            to={{
              pathname: "/panel",
              state: {
                email: this.state.email,
                token: this.state.token,
                uid: this.state.uid
              }
            }}
          />
        ) : null}
        <Footer />
      </div>
    );
  }
}
