import React, { Component } from "react";
import NoPhoto from "./Images/noPhoto.png";
import { Modal, Input, Popover, Upload, Icon, message, Select } from "antd";

export default class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirm: false,
      showModal: false,
      newsTitle: "",
      body: "",
      active: "",
      newsSubtitle: "",
      editSuccess: "",
      loadingSubmit: false,
      editStatus: this.props.active,
      popover: false,
      deleted: "",
      imageUrl: "",
      submittingChange: "",
      users: this.props.users
    };
  }

  async downloadMoreData() {
    await fetch(
      process.env.REACT_APP_BACKEND_HOST + `/1.0/news/admin/news/${this.props.id}`, {
      method: "get",
      headers: {
        Authorization: `Bearer ${this.props.token}`
      }
    })
      .then(x => x.json())
      .then(resp => {
        console.log(resp)
        if (resp.photo === null) {
          this.setState({
            newsSubtitle: resp.content.subtitle,
            body: resp.content.body,
            body2: resp,
            newsTitle: resp.content.title,
            active: resp.is_active,
            imageUrl: ""
          });
        } else {
          this.setState({
            newsSubtitle: resp.content.subtitle,
            body: resp.content.body,
            body2: resp,
            newsTitle: resp.content.title,
            active: resp.is_active,
            imageUrl: resp.photo
          });
        }
      });
  }

  async deleteNews() {
    this.setState({
      loadingSubmit: true
    });
    await fetch(
      `https://api.origintag.com/api-panel/news/${this.props.id}`, {
      method: "delete",
      headers: {
        Authorization: `Bearer ${this.props.token}`
      }
    })
      .then(x => x.json())
      .then(() => this.setState({ popover: false, deleted: true }))
      .catch(() => this.setState({ deleted: false }));
  }

  confirmDelete() {
    this.setState({
      showConfirm: !this.state.showConfirm
    });
  }

  async change() {
    this.setState({
      loadingSubmit: true,
      submittingChange: "yes"
    });

    console.log(this.state)

    let date = new Date()
    let isodate = date.toISOString()
    console.log(this.state.imageUrl)
    let body = this.state.imageUrl.includes("http")
      ? JSON.stringify({
        name: this.state.newsTitle,
        news_date: isodate,
        content: [
          {
            title: this.state.newsTitle,
            subtitle: this.state.newsSubtitle,
            body: this.state.body,
            language: "en"
          }
        ],
        is_active: this.state.active
      })
      : JSON.stringify({
        name: this.state.newsTitle,
        news_date: isodate,
        content: [
          {
            title: this.state.newsTitle,
            subtitle: this.state.newsSubtitle,
            body: this.state.body,
            language: "en"
          }
        ],
        is_active: this.state.active,
        photo: this.state.imageUrl
      });
    await fetch(
      //`https://test.api.stellarosa.talkinthings.com/1.0/news/admin/news/${this.props.id}`
      process.env.REACT_APP_BACKEND_HOST + `/1.0/news/admin/news/${this.props.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.token}`,
        Accept: "application/json"
      },
      body: body
    })
      .then(x => x.json())
      .then(() => {
        this.setState({ loadingSubmit: false, showModal: false, submittingChange: "worked" });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          loadingSubmit: false,
          submittingChange: "notworked"
        });
      });
    this.props.sendData("Worked")
    this.setState({
      submittingChange: ""
    })
  }

  showModal() {
    this.downloadMoreData()
    this.setState({
      showModal: !this.state.showModal,
      editSuccess: ""
    });
  }

  changeTitle(e) {
    this.setState({
      newsTitle: e.target.value
    });
  }

  changeSubtitle(e) {
    this.setState({
      newsSubtitle: e.target.value
    });
  }

  changeBody(e) {
    this.setState({
      body: e.target.value
    });
  }
  beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  handleChange = info => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      this.getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false
        })
      );
    }
  };

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  changeActive(e) {
    this.setState({
      active: e
    });
  }

  render() {
    const content = (
      <div>
        <span>Do you want to delete the message?</span>
        <div className="mt-2">
          <button
            className="btn btn-sm"
            onClick={() => this.deleteNews()}
            style={{ color: "white", backgroundColor: "#be9e55" }}
          >
            Yes
          </button>
          <button
            className="btn btn-sm"
            style={{ float: "right", border: "0.1px solid rgba(0,0,0, 0.3)" }}
            onClick={() => this.setState({ popover: false, deleted: "" })}
          >
            No
          </button>
        </div>
        {this.state.deleted === true ? (
          <div
            className="my-2 rounded"
            style={{
              textAlign: "center",
              backgroundColor: "#d4edda",
              color: "#3e774b"
            }}
          >
            Deleted the news
          </div>
        ) : null}
        {this.state.deleted === false ? (
          <div
            className="my-2 rounded"
            style={{
              textAlign: "center",
              backgroundColor: "#f8d7da",
              color: "#975057"
            }}
          >
            Something went wrong
          </div>
        ) : null}
      </div>
    );

    const { Option } = Select;
    return (
      <tr style={{ textAlign: "center", position: "relative" }}>
        <td>
          <img
            style={{ width: 50, height: 50 }}
            src={this.props.imageUrl !== null ? this.props.imageUrl : NoPhoto}
            alt=""
          />
        </td>
        <td style={{ fontSize: 15, textAlign: "left" }} className="py-3 pl-3">
          {this.props.title}
        </td>
        <td
          style={{
            fontSize: 15,
            textAlign: "left",
            width: 220
          }}
        >
          {this.props.newsDate[5] +
            this.props.newsDate[6] +
            "/" +
            this.props.newsDate[8] +
            this.props.newsDate[9] +
            "/" +
            this.props.newsDate[0] +
            this.props.newsDate[1] +
            this.props.newsDate[2] +
            this.props.newsDate[3]}
        </td>
        <td
          style={{
            fontSize: 15,
            textAlign: "left",
            width: 190
          }}
        >
          {this.props.active === true ? (
            <span
              className="badge badge-pill"
              style={{
                backgroundColor: "#be9e55",
                color: "white",
                fontSize: 14,
                fontWeight: "normal"
              }}
            >
              Active
            </span>
          ) : (
              <span
                className="badge badge-pill"
                style={{
                  backgroundColor: "#c4c5d6",
                  color: "white",
                  fontSize: 14,
                  fontWeight: "normal"
                }}
              >
                Inactive
              </span>
            )}
        </td>
        <td style={{ width: 100 }}>
          {this.props.status !== "Sent" ? (
            <div>
              <Icon
                type="form"
                onClick={() => this.showModal()}
                size="1.5em"
                style={{ cursor: "pointer" }}
              />
            </div>
          ) : (
              <div>
                <Popover
                  content={content}
                  title="Delete news?"
                  trigger="click"
                  visible={this.state.popover}
                >
                  <Icon
                    type="delete"
                    size="1.5em"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setState({ popover: true })}
                  />
                </Popover>

                <Icon
                  type="form"
                  size="1.5em"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.showModal()}
                />
              </div>
            )}
        </td>
        <Modal
          centered={true}
          title={<p style={{ fontWeight: "bold" }}>Edit news</p>}
          visible={this.state.showModal}
          onOk={() => this.showModal()}
          onCancel={() => this.showModal()}
          footer={[
            <button
              className="btn"
              onClick={() => this.setState({ showModal: false })}
              style={{ border: "1px solid rgba(0,0,0,0.2)" }}
            >
              Return
              </button>,
            <button
              className="btn"
              onClick={() => this.change()}
              style={{ backgroundColor: "#be9e55", color: "white" }}
            >
              Submit
              </button>
          ]}
        >
          <label htmlFor="" className="mt-2">
            Title
            </label>
          <Input
            value={this.state.body2 !== undefined ? this.state.newsTitle : "Loading..."}
            onChange={e => this.changeTitle(e)}
          />
          <label htmlFor="" className="mt-1">
            Subtitle
            </label>
          <Input
            value={this.state.body2 !== undefined ? this.state.newsSubtitle : "Loading..."}
            onChange={e => this.changeSubtitle(e)}
          />
          <label htmlFor="" className="mt-1">
            Body
            </label>
          <textarea
            onChange={e => this.changeBody(e)}
            value={this.state.body2 !== undefined ? this.state.body : "Loading..."}
            style={{
              height: "170px",
              width: "100%",
              border: "1px solid rgba(0,0,0,0.15)"
            }}
            className="py-2 rounded"
          />
          <label htmlFor="">Status</label> <br />
          <Select
            placeholder="Change status"
            defaultValue={true}
            style={{ width: "100%" }}
            onChange={e => this.changeActive(e)}
            value={this.state.body2 !== undefined ? this.state.active : false}
          >
            <Option value={true}>Active</Option>
            <Option value={false}>Inactive</Option>
          </Select>
          <div style={{ textAlign: "center" }}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader mt-3"
              showUploadList={false}
              beforeUpload={this.beforeUpload}
              onChange={this.handleChange}
            >
              <img
                src={
                  this.state.body2 !== undefined ? (
                    this.state.imageUrl
                  ) : (
                      <Icon type="plus" />
                    )
                }
                alt=""
                style={{ width: 200 }}
              />
            </Upload>
          </div>
          {this.state.submittingChange === "yes" ? (
            message.loading("Submitting the change...")
          ) : null}
          {this.state.submittingChange === "worked" ? (
            message.success("Submitted the change")
          ) : null}
          {this.state.submittingChange === "notworked" ? (
            message.error("Didn't work, check credentials")
          ) : null}
        </Modal>
      </tr>
    );
  }
}
