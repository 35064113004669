import React, { Component } from "react";

export default class UserTwo extends Component {
  render() {
    return (
      <tr style={{ position: "relative", width: "100%" }}>
        <td className="py-3 pl-3">
          {this.props.first_name} {this.props.last_name}
        </td>
        <td className="py-3">{this.props.email}</td>
        <td className="py-3">{this.props.zip_code}</td>
        <td className="py-3">{this.props.gender}</td>
        <td className="py-3">{`${this.props.reg_date[5]}${this.props.reg_date[6]}/${this.props.reg_date[8]}${this.props.reg_date[9]}/${this.props.reg_date[0]}${this.props.reg_date[1]}${this.props.reg_date[2]}${this.props.reg_date[3]}`}</td>
      </tr>
    );
  }
}
