import React, { Component } from "react";
import { Select, Input, Upload, Icon, message, Empty } from "antd";
import { DatePicker as DatePicker2 } from "antd";
import "antd/dist/antd.css";
import "rsuite/dist/styles/rsuite-default.css";
import { IoIosAdd } from "react-icons/io";
import News from "./News";
import { DatePicker, Input as Input2, Pagination, SelectPicker } from "rsuite";

export default class NewsLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      date: "",
      statusFilter: "all",
      changedSearch: false,
      changedDate: false,
      changedStatus: true,
      show: "list",
      newsTitle: "",
      newsDate: "",
      newsStatus: true,
      newsDescription: "",
      news: "",
      addedMessage: "",
      showModal: false,
      prev: true,
      next: true,
      first: true,
      last: true,
      ellipsis: true,
      boundaryLinks: false,
      currentPageSearch: 1,
      currentPageStatus: 1,
      currentPageDate: 1,
      newsPerPage: 10,
      error: false,
      status: {
        addedMessage: "",
      },
    };
    this.handleSelectSearch = this.handleSelectSearch.bind(this);
    this.handleDateSearch = this.handleDateSearch.bind(this);
    this.handleStatusSearch = this.handleStatusSearch.bind(this);
    this.downloadNewsList = this.downloadNewsList.bind(this);
    this.getData = this.getData.bind(this);
  }

  handleSelectSearch(eventKey) {
    this.setState({ currentPageSearch: eventKey });
  }

  handleStatusSearch(eventKey) {
    this.setState({ currentPageStatus: eventKey });
  }

  handleDateSearch(eventKey) {
    this.setState({ currentPageDate: eventKey });
  }

  componentWillMount() {
    this.downloadNewsList();
    this.downloadUsers();
  }

  showModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  search(e) {
    this.setState({
      search: e.target.value,
      date: "",
      status: "all",
      changedSearch: true,
      changedDate: false,
      changedStatus: false,
      currentPageSearch: 1,
    });
  }

  setDate(e) {
    if (e !== null) {
      this.setState({
        date: e._d.toISOString(),
        search: "",
        status: "all",
        changedSearch: false,
        changedDate: true,
        changedStatus: false,
        currentPageDate: 1,
      });
    } else {
      this.setState({
        date: "",
        search: "",
        status: "all",
        changedSearch: false,
        changedDate: false,
        changedStatus: true,
        currentPageDate: 1,
      });
    }
  }

  setStatus(e) {
    this.setState({
      date: "",
      search: "",
      status: e,
      changedSearch: false,
      changedDate: false,
      changedStatus: true,
      currentPageStatus: 1,
    });
  }

  async downloadNewsList() {
    await fetch(
      process.env.REACT_APP_BACKEND_HOST +
        `/1.0/news/admin/news?page_size=3000`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((x) => x.json())
      .then((resp) => {
        console.log(resp);
        if (resp.status === 401 || resp.status === 500) {
          this.setState({
            error: true,
          });
        }
        this.setState({
          news: resp,
        });
      })
      .catch((err) => this.setState({ error: true }));
  }

  setAddNews() {
    this.setState({
      show: "addNews",
    });
  }

  setNewsList() {
    this.setState({
      show: "list",
    });
  }

  beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      this.getBase64(info.file.originFileObj, (imageUrl) => {
        this.setState({
          imageUrl,
          loading: false,
        });
      });
    }
  };

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  onChange(e) {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  }

  uu(e) {
    this.setState({
      newsTitle: e,
    });
  }

  setNewsDate(e) {
    this.setState({
      newsDate: e,
    });
  }

  async getData() {
    await fetch(
      process.env.REACT_APP_BACKEND_HOST +
        `/1.0/news/admin/news?page_size=3000`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((x) => x.json())
      .then((resp) => {
        console.log(resp);
        if (resp.status === 401 || resp.status === 500) {
          this.setState({
            error: true,
          });
        }
        this.setState({
          news: resp,
        });
      })
      .catch((err) => this.setState({ error: true }));
  }

  setNewsStatus(e) {
    if (e === "true")
      this.setState({
        newsStatus: true,
      });
    else {
      this.setState({
        newsStatus: false,
      });
    }
  }

  async downloadUsers() {
    await fetch(
      process.env.REACT_APP_BACKEND_HOST + `/1.0/auth/admin/users?role=mobile`,
      {
        method: "get",
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((x) => x.json())
      .then((resp) => {
        this.setState({
          users: resp,
        });
      });
  }

  async addNews() {
    let users = this.state.users.map((x) => {
      return x.uid;
    });
    let newsDate = this.state.newsDate.toISOString();
    let date =
      newsDate[0] +
      newsDate[1] +
      newsDate[2] +
      newsDate[3] +
      newsDate[4] +
      newsDate[5] +
      newsDate[6] +
      newsDate[7] +
      newsDate[8] +
      newsDate[9] +
      newsDate[10] +
      newsDate[11] +
      newsDate[12] +
      newsDate[13] +
      newsDate[14] +
      newsDate[15] +
      newsDate[16] +
      newsDate[17] +
      newsDate[18];
    let body = {
      name: this.state.newsTitle,
      news_date: date,
      users: users,
      total_users: users.length,
      content: [
        {
          title: this.state.newsTitle,
          subtitle: this.state.newsTitle,
          body: this.state.newsDescription,
          language: "en",
        },
      ],
      is_active: this.state.newsStatus,
      photo: this.state.imageUrl,
    };
    console.log(body);
    await fetch(
      process.env.REACT_APP_BACKEND_HOST + `/1.0/news/admin/news`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${this.props.token}`,
        },
        body: JSON.stringify(body),
      }
    )
      .then((x) => x.json())
      .then((resp) => {
        let that = this;
        console.log(resp);
        if (resp.status === 400 || resp.status === 401 || resp.status === 500) {
          that.setStatus({
            addedMessage: false,
          });
        } else {
          that.setState({
            addedMessage: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          addedMessage: false,
        });
      });
  }

  render() {
    const { Option } = Select;
    const { Search } = Input;
    const { TextArea } = Input;

    console.log(this.state);

    const uploadButton = (
      <div style={{ width: 200, height: 200, color: "#be9e55" }}>
        <Icon
          style={{ marginTop: 80 }}
          type={this.state.loading ? "loading" : "plus"}
        />
        <div className="ant-upload-text">Add visual</div>
      </div>
    );

    if (
      this.state.show === "list" &&
      this.state.news !== "" &&
      this.state.error === false
    ) {
      let fetchedNews = this.state.news.map((x) => {
        return (
          <News
            id={x.uid}
            title={x.name}
            newsDate={x.news_date}
            active={x.is_active}
            imageUrl={x.photo}
            token={this.props.token}
            sendData={this.getData}
            users={this.state.users}
          />
        );
      });

      let newsFilterSearch = fetchedNews.filter((x) => {
        return x.props.title
          .toLowerCase()
          .includes(this.state.search.toLowerCase());
      });

      let newsFilterStatus = fetchedNews.filter((x) => {
        if (this.state.statusFilter === "all") {
          return x;
        } else if (this.state.statusFilter === "active") {
          return x.props.active === true;
        } else {
          return x.props.active === false;
        }
      });

      let newsFilterDate = fetchedNews.filter((x) => {
        let newsDate =
          x.props.newsDate[0] +
          x.props.newsDate[1] +
          x.props.newsDate[2] +
          x.props.newsDate[3] +
          x.props.newsDate[4] +
          x.props.newsDate[5] +
          x.props.newsDate[6] +
          x.props.newsDate[7] +
          x.props.newsDate[8] +
          x.props.newsDate[9];

        let stateDate =
          this.state.date[0] +
          this.state.date[1] +
          this.state.date[2] +
          this.state.date[3] +
          this.state.date[4] +
          this.state.date[5] +
          this.state.date[6] +
          this.state.date[7] +
          this.state.date[8] +
          this.state.date[9];
        return newsDate === stateDate;
      });

      const {
        currentPageSearch,
        currentPageStatus,
        currentPageDate,
        newsPerPage,
      } = this.state;

      const indexOfLastNewsSearch = currentPageSearch * newsPerPage;
      const indexOfLastNewsStatus = currentPageStatus * newsPerPage;
      const indexOfLastNewsDate = currentPageDate * newsPerPage;
      const indexOfFirstNewsStatus = indexOfLastNewsStatus - newsPerPage;
      const indexOfFirstNewsSearch = indexOfLastNewsSearch - newsPerPage;
      const indexOfFirstNewsDate = indexOfLastNewsDate - newsPerPage;
      const currentPageSearchNews = newsFilterSearch.slice(
        indexOfFirstNewsSearch,
        indexOfLastNewsSearch
      );
      const currentPageStatusNews = newsFilterStatus.slice(
        indexOfFirstNewsStatus,
        indexOfLastNewsStatus
      );
      const currentPageDateNews = newsFilterDate.slice(
        indexOfFirstNewsDate,
        indexOfLastNewsDate
      );

      const pageNumbersSearch = [];
      for (
        let i = 1;
        i <= Math.ceil(newsFilterSearch.length / newsPerPage);
        i++
      ) {
        pageNumbersSearch.push(i);
      }

      const pageNumbersStatus = [];
      for (
        let i = 1;
        i <= Math.ceil(newsFilterStatus.length / newsPerPage);
        i++
      ) {
        pageNumbersStatus.push(i);
      }

      const pageNumbersDate = [];
      for (
        let i = 1;
        i <= Math.ceil(newsFilterDate.length / newsPerPage);
        i++
      ) {
        pageNumbersDate.push(i);
      }

      const renderPageNumbersStatus = pageNumbersStatus.map((number) => {
        return (
          <li key={number} id={number} onClick={this.handleClick}>
            {number}
          </li>
        );
      });

      const renderPageNumbersSearch = pageNumbersSearch.map((number) => {
        return (
          <li key={number} id={number} onClick={this.handleClick}>
            {number}
          </li>
        );
      });

      const renderPageNumbersDate = pageNumbersDate.map((number) => {
        return (
          <li key={number} id={number} onClick={this.handleClick}>
            {number}
          </li>
        );
      });

      return (
        <div className="row">
          <div className="col-lg-12 py-4 pl-5">
            <p style={{ fontSize: 25 }}>News - news list</p>
          </div>
          <div
            style={{ width: "96%", margin: "auto", height: "45rem" }}
            className="pb-3 pt-2 shadow"
          >
            <div className="col-lg-12 py-2 border-bottom">
              <div className="row">
                <div className="col-lg-3 pl-5">
                  <span>
                    Status:
                    <Select
                      className="ml-2"
                      style={{ width: 250 }}
                      defaultValue="all"
                      onChange={(e) => this.setStatus(e)}
                      value={this.state.statusFilter}
                    >
                      <Option key="all">All</Option>
                      <Option key="active">Active</Option>
                      <Option key="inactive">Inactive</Option>
                    </Select>
                  </span>
                </div>
                <div className="col-lg-3">
                  <span>
                    Date:
                    <DatePicker2
                      className="ml-2"
                      style={{ width: 250 }}
                      onChange={(e) => this.setDate(e)}
                      format="MM/DD/YYYY"
                    />
                  </span>
                </div>
                <div className="col-lg-3">
                  <span style={{ margin: 0, padding: 0 }}>
                    <Search
                      style={{ width: 250 }}
                      placeholder="Search..."
                      onChange={(e) => this.search(e)}
                      value={this.state.search}
                    />
                  </span>
                </div>
                <div className="col-lg-1" />
                <div className="col-lg-2" style={{ textAlign: "right" }}>
                  <button
                    className="btn"
                    style={{ color: "red" }}
                    onClick={() => this.setAddNews()}
                  >
                    Add news <IoIosAdd />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-4">
              <div
                style={{
                  width: "98%",
                  margin: "auto",
                }}
              >
                <table className="table-striped" style={{ width: "100%" }}>
                  <thead style={{ backgroundColor: "#f4f5f8" }}>
                    <tr>
                      <th style={{ width: 30 }}></th>
                      <th className="py-3 pl-3" style={{ textAlign: "left" }}>
                        News Title
                      </th>
                      <th style={{ textAlign: "left" }}>News Date</th>
                      <th style={{ textAlign: "left" }}>Status</th>
                      {/*<th style={{ textAlign: "left" }}>Last modification</th>*/}
                      {/*<th style={{ textAlign: "left" }}>Last editor</th>*/}
                      <th style={{ textAlign: "center" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.changedSearch === true
                      ? currentPageSearchNews
                      : null}
                    {this.state.changedStatus === true
                      ? currentPageStatusNews
                      : null}
                    {this.state.changedDate === true
                      ? currentPageDateNews
                      : null}
                  </tbody>
                </table>
                {this.state.changedSearch === true ? (
                  newsFilterSearch.length === 0 ? (
                    <Empty className="mt-3" />
                  ) : null
                ) : null}
                {this.state.changedStatus === true ? (
                  newsFilterStatus.length === 0 ? (
                    <Empty className="mt-3" />
                  ) : null
                ) : null}
                {this.state.changedDate === true ? (
                  newsFilterDate.length === 0 ? (
                    <Empty className="mt-3" />
                  ) : null
                ) : null}
                {/*{this.state.changedSearch === true
                  ? renderPageNumbersSearch
                  : null}
                {this.state.changedStatus === true
                  ? renderPageNumbersStatus
                  : null}
                {this.state.changedDate === true ? renderPageNumbersDate : null}*/}
                <div className="row pt-4">
                  <div className="col-lg-4"></div>
                  <div
                    className="col-lg-4 pb-3"
                    style={{ textAlign: "center" }}
                  >
                    {this.state.changedSearch === true &&
                    renderPageNumbersSearch.length > 1 ? (
                      <Pagination
                        {...this.state}
                        pages={renderPageNumbersSearch.length}
                        maxButtons={5}
                        activePage={this.state.currentPageSearch}
                        onSelect={this.handleSelectSearch}
                      />
                    ) : null}
                    {this.state.changedStatus === true &&
                    renderPageNumbersStatus.length > 1 ? (
                      <Pagination
                        {...this.state}
                        pages={renderPageNumbersStatus.length}
                        maxButtons={5}
                        activePage={this.state.currentPageStatus}
                        onSelect={this.handleStatusSearch}
                      />
                    ) : null}

                    {this.state.changedDate === true &&
                    renderPageNumbersDate.length > 1 ? (
                      <Pagination
                        {...this.state}
                        pages={renderPageNumbersDate.length}
                        maxButtons={5}
                        activePage={this.state.currentPageDate}
                        onSelect={this.handleDateSearch}
                      />
                    ) : null}
                  </div>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-3" style={{ textAlign: "center" }}>
                    {(this.state.changedSearch === true &&
                      newsFilterSearch.length > 0) ||
                    (this.state.changedDate === true &&
                      newsFilterDate.length > 0) ||
                    (this.state.changedStatus === true &&
                      newsFilterStatus.length > 0) ? (
                      <span style={{ opacity: 0.6 }}>
                        Displaying{" "}
                        {this.state.changedSearch === true
                          ? indexOfFirstNewsSearch + 1
                          : null}
                        {this.state.changedStatus === true
                          ? indexOfFirstNewsStatus + 1
                          : null}
                        {this.state.changedDate === true
                          ? indexOfFirstNewsDate + 1
                          : null}{" "}
                        -{" "}
                        {this.state.changedSearch === true
                          ? `${indexOfLastNewsSearch}`
                          : null}
                        {this.state.changedStatus === true
                          ? `${indexOfLastNewsStatus}`
                          : null}
                        {this.state.changedDate === true
                          ? `${indexOfLastNewsDate}`
                          : null}{" "}
                        of{" "}
                        {this.state.changedSearch === true
                          ? newsFilterSearch.length
                          : null}
                        {this.state.changedStatus === true
                          ? newsFilterStatus.length
                          : null}
                        {this.state.changedDate === true
                          ? newsFilterDate.length
                          : null}{" "}
                        news
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.show !== "list") {
      return (
        <div className="row">
          <div className="col-lg-12 py-4 pl-5">
            <p style={{ fontSize: 25 }}>News - create news</p>
          </div>
          <div style={{ width: "96%", margin: "auto" }} className="pb-3 pt-2">
            <div className="col-lg-12 py-2">
              <div className="row">
                <div className="col-lg-12">
                  <p>Enter news title and date</p>
                </div>
                <div className="col-lg-12">
                  <div className="row py-3 shadow-sm">
                    {/*}
                    <div className="col-lg-1">
                      <button className="btn">News title: </button>
                    </div>

                    <div className="col-lg-9">
                      <div className="row">
                        <div className="col-lg-3">
                          <Input2
                            style={{
                              width: "100%",
                              border: "none"
                            }}
                            placeholder="enter news title"
                            name="newsTitle"
                            onChange={e => this.uu(e)}
                            value={this.state.newsTitle}
                          />
                        </div>
                        {
                          <div className="col-lg-3">
                            <DatePicker
                              oneTap
                              appearance="subtle"
                              placeholder="select news date"
                              style={{
                                width: "100%",
                                color: "black"
                              }}
                              onChange={e => this.setNewsDate(e)}
                              value={this.state.newsDate}
                            />
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <button className="btn">status: </button>
                      <Select
                        defaultValue="true"
                        className="pl-2"
                        style={{ width: "70%", float: "right", border: "none" }}
                        onChange={e => this.setNewsStatus(e)}
                      >
                        <Option value="true">Active</Option>
                        <Option value="false">Inactive</Option>
                      </Select>
                    </div>
                      */}
                    <div className="col-lg-1 pl-5 mt-2">News:</div>
                    <div className="col-lg-3 mt-2">
                      <span>
                        <Input2
                          style={{
                            width: "50%",
                            border: "none",
                            padding: 0,
                            margin: 0,
                            color: "#1675e0",
                          }}
                          placeholder="enter news title"
                          onChange={(e) => this.uu(e)}
                          value={this.state.newsTitle}
                        />
                      </span>
                    </div>
                    <div className="col-lg-3">
                      <span>
                        Date:
                        <DatePicker
                          className="pl-2"
                          oneTap
                          appearance="subtle"
                          placeholder="select news date"
                          style={{
                            width: 250,
                            color: "black",
                          }}
                          onChange={(e) => this.setNewsDate(e)}
                          value={this.state.newsDate}
                        />
                      </span>
                    </div>
                    <div className="col-lg-3">
                      <button
                        className="btn"
                        style={{ margin: 0, padding: 0, fontSize: 14 }}
                      >
                        Status:
                      </button>
                      <SelectPicker
                        data={[
                          {
                            label: "Active",
                            value: "true",
                          },
                          {
                            label: "Inactive",
                            value: "false",
                          },
                        ]}
                        searchable={false}
                        style={{ width: 250 }}
                        onChange={(e) => this.setNewsStatus(e)}
                        className="ml-2"
                        appearance="subtle"
                      />
                    </div>
                    <div className="col-lg-2"></div>
                  </div>
                </div>
                <div className="col-lg-12 my-4">
                  <div className="row py-5">
                    <div className="col-lg-2" style={{ textAlign: "center" }}>
                      Add visual file <br /> 0000x0000 PNG, JPG
                      <Upload
                        listType="picture-card"
                        className="avatar-uploader mt-1"
                        showUploadList={false}
                        beforeUpload={this.beforeUpload}
                        onChange={this.handleChange}
                        style={{ width: 700 }}
                      >
                        {this.state.imageUrl ? (
                          <img
                            src={this.state.imageUrl}
                            alt="avatar"
                            style={{ width: 200, height: 200 }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </div>
                    <div className="col-lg-1" />
                    <div className="col-lg-9">
                      Add description
                      <div className="shadow-sm mt-3">
                        <TextArea
                          rows={4}
                          name="newsDescription"
                          onChange={(e) => this.onChange(e)}
                          placeholder="Type your description here"
                          autosize={{ minRows: 11, maxRows: 11 }}
                          style={{ border: "none" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-4" style={{ textAlign: "left" }}>
                      <p
                        onClick={() => {
                          this.setState({ show: "list" });
                        }}
                        style={{
                          marginLeft: 40,
                          cursor: "pointer",
                        }}
                      >
                        Cancel
                      </p>
                    </div>
                    <div className="col-lg-4" style={{ textAlign: "center" }}>
                      <button
                        className="btn py-3 rounded-pill"
                        style={
                          this.state.newsDate !== "" &&
                          this.state.newsDescription !== "" &&
                          this.state.newsStatus !== "" &&
                          this.state.newsTitle !== "" &&
                          (this.state.imageUrl !== undefined ||
                            this.state.imageUrl !== "")
                            ? {
                                backgroundColor: "#c10230",
                                color: "white",
                                width: 300,
                              }
                            : {
                                backgroundColor: "#d9d9e4",
                                color: "white",
                                width: 300,
                              }
                        }
                        disabled={
                          this.state.newsDate !== "" &&
                          this.state.newsDescription !== "" &&
                          this.state.newsStatus !== "" &&
                          this.state.newsTitle !== "" &&
                          (this.state.imageUrl !== undefined ||
                            this.state.imageUrl !== "")
                            ? false
                            : true
                        }
                        onClick={() => this.addNews()}
                      >
                        Add news
                      </button>
                    </div>
                    <div className="col-lg-4" />
                  </div>
                </div>
              </div>
            </div>
            {this.state.addedMessage === true ||
            this.state.status.addedMessage === true ? (
              <div className="col-lg-12 mt-2">
                <div className="row">
                  <div
                    className="alert alert-success"
                    style={{ width: "100%" }}
                  >
                    Added the message!
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.addedMessage === false ||
            this.state.status.addedMessage === false ? (
              <div className="col-lg-12 mt-2">
                <div className="row">
                  <div className="alert alert-danger" style={{ width: "100%" }}>
                    Failed to send message, check data and try again
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      );
    } else if (this.state.news === "" && this.state.error === false) {
      return (
        <div>
          <p className="pl-2 mt-5">
            Loading messages <Icon className="ml-3" type="loading" />
          </p>
        </div>
      );
    } else if (this.state.error === true) {
      return (
        <div>
          <p className="p-2">
            Fetch error. Wait a couple minutes and try again.{" "}
          </p>
        </div>
      );
    }
  }
}
