import React, { Component } from "react";
import { Select, Empty, Icon, Modal, Input, message } from "antd";
import Search from "antd/lib/input/Search";
import User from "./User";
import { Pagination } from "rsuite";
import { IoIosAdd } from "react-icons/io";

export default class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "all",
      filterRole: "all",
      search: "",
      show: "list",
      email: "",
      role: "",
      filter: "email",
      users: "",
      currentPage: 1,
      usersPerPage: 10,
      prev: true,
      next: true,
      first: true,
      last: true,
      ellipsis: true,
      boundaryLinks: false,
      error: false,
      showAddManager: false,
      editEmail: "",
      editFirstName: "",
      editLastName: "",
      editPassword: "",
      editRole: "admin"
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.getUsersAgain = this.getUsersAgain.bind(this)
  }

  handleSelect(eventKey) {
    this.setState({ currentPage: eventKey });
  }

  handleSetProp(key, value) {
    this.setState({ [key]: value });
  }

  setStatus(e) {
    this.setState({
      status: e,
      filter: "status"
    });
  }

  setSearch(e) {
    if (e !== "") {
      this.setState({
        search: e.target.value,
        filter: "search"
      });
    } else {
      this.setState({
        filter: "search",
        status: "all"
      });
    }
  }

  setAddUser() {
    this.setState({
      show: "addUser"
    });
  }

  setUserList() {
    this.setState({
      show: "list"
    });
  }

  changeRole(e) {
    this.setState({
      role: e.target.name
    });
  }

  setEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  async getUsers() {
    await fetch(
      process.env.REACT_APP_BACKEND_HOST + '/1.0/auth/admin/users?role=regular,admin'
      , {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        method: "get"
      })
      .then(x => x.json())
      .then(resp => {
        if (resp.status === 401 || resp.status === 500) {
          this.setState({
            error: true
          })
        }
        this.setState({
          users: resp
        });
      })
      .catch(err => this.setState({ error: true }));
  }

  async getUsersAgain() {
    await fetch(
      process.env.REACT_APP_BACKEND_HOST + '/1.0/auth/admin/users?role=regular,admin'
      , {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        method: "get"
      })
      .then(x => x.json())
      .then(resp => {
        if (resp.status === 401 || resp.status === 500) {
          this.setState({
            error: true
          })
        }
        console.log(this.state)
        this.setState({
          users: resp
        });
        this.render()
      })
      .catch(err => this.setState({ error: true }));
  }

  componentWillMount() {
    setInterval(() => this.getUsers(), 30000);
    this.getUsers();
  }

  async addUser() {
    await fetch({})
      .then(x => x.json())
      .then(resp => console.log(resp))
      .catch(err => console.log(err));
  }

  handleClick(event) {
    this.setState({
      currentPage: event.target.id
    });
  }

  async addManager() {

    let body = JSON.stringify({
      email: this.state.editEmail,
      first_name: this.state.editFirstName,
      last_name: this.state.editLastName,
      password: this.state.editPassword,
      role: this.state.editRole
    })

    await fetch(
      process.env.REACT_APP_BACKEND_HOST + '/1.0/auth/admin/users'
      , {
      method: "post",
      headers: {
        Authorization: `Bearer ${this.props.token}`,
        Accept: "application/jsom",
        "Content-Type": "application/json",
      },
      body: body
    }).then(x => x.json()).then(() => message.success("Added user")).catch(() => message.error("Couldn't add user"))
  }

  render() {
    const { Option } = Select;

    if (this.state.show === "list" && this.state.users !== "" && this.state.error === false) {
      console.log(this.state.users)
      let users = this.state.users.map(x => {
        return (
          <User
            firstName={x.first_name}
            lastName={x.last_name}
            email={x.email}
            active={x.active}
            uid={x.uid}
            token={this.props.token}
            role={x.role}
            sendData={this.getUsersAgain}
          />
        );
      });

      let filtered = users.filter(x => {
        let names = `${x.props.firstName} ${x.props.lastName}`
        if (this.state.filter === "search") {
          return names
            .toLowerCase()
            .includes(this.state.search.toLowerCase());
        }
        if (this.state.status === "active") {
          return x.props.active === true;
        }
        if (this.state.status === "inactive") {
          return x.props.active === false;
        } else {
          return x;
        }
      });

      const { currentPage, usersPerPage } = this.state;
      const indexOfLastUser = currentPage * usersPerPage;
      const indexOfFirstUser = indexOfLastUser - usersPerPage;
      const currentUsers = filtered.slice(indexOfFirstUser, indexOfLastUser);

      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(users.length / usersPerPage); i++) {
        pageNumbers.push(i);
      }

      const renderPageNumber = pageNumbers.map(number => {
        return (
          <span key={number} id={number} onClick={this.handleClick}>
            {number}
          </span>
        );
      });

      return (
        <div className="row">
          <div className="col-lg-12 py-4 pl-5">
            <p style={{ fontSize: 25 }}>User management</p>
          </div>
          <div
            style={{ width: "96%", margin: "auto" }}
            className="pb-3 pt-2 shadow"
          >
            <div className="col-lg-12 py-2 border-bottom">
              <div className="row">
                <div className="col-lg-3 pl-5">
                  <span>
                    Status:
                    <Select
                      className="ml-2"
                      style={{ width: 250 }}
                      defaultValue="all"
                      onChange={e => this.setStatus(e)}
                      value={this.state.status}
                    >
                      <Option key="all">All</Option>
                      <Option key="active">Active</Option>
                      <Option key="inactive">Inactive</Option>
                    </Select>
                  </span>
                </div>
                <div className="col-lg-3">
                  <span>
                    <Search
                      style={{ width: 250 }}
                      placeholder="Search by name"
                      onChange={e => this.setSearch(e)}
                      value={this.state.search}
                    />
                  </span>
                </div>
                <div className="col-lg-4" />
                <div className="col-lg-2" style={{ textAlign: "right" }}>
                  <button
                    className="btn"
                    style={{ color: "red" }}
                    onClick={() => this.setState({ showAddManager: true })}
                  >
                    Add manager <IoIosAdd />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-4">
              <div style={{ width: "98%", margin: "auto" }}>
                <table className="table-striped" style={{ width: "100%" }}>
                  <thead style={{ backgroundColor: "#f4f5f8" }}>
                    <tr>
                      <th className="py-3 pl-3" style={{ textAlign: "left" }}>
                        Name
                      </th>
                      <th style={{ textAlign: "left" }}>Email</th>
                      <th style={{ textAlign: "left" }}>Status</th>
                      <th style={{ textAlign: "left" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>{currentUsers}</tbody>
                </table>
                {currentUsers.length > 0 ? null : <Empty className="mt-5" />}
                <div className="row">
                  <div className="col-lg-4"></div>
                  <div className="col-lg-4" style={{ textAlign: "center" }}>
                    {renderPageNumber.length > 1 ? (
                      <Pagination
                        {...this.state}
                        pages={renderPageNumber.length}
                        maxButtons={5}
                        activePage={this.state.currentPage}
                        onSelect={this.handleSelect}
                      />
                    ) : null}
                  </div>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-3" style={{ textAlign: "center" }}>
                    <span style={{ opacity: 0.6 }}>
                      {currentUsers.length > 0 ? (
                        <div className="mt-3">
                          Displaying {indexOfFirstUser + 1} - {indexOfLastUser}{" "}
                          of {users.length} users{" "}
                        </div>
                      ) : null}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            centered={true}
            title={<p style={{ fontWeight: "bold" }}>Add manager</p>}
            visible={this.state.showAddManager}
            onOk={() => this.setState({ showAddManager: false })}
            onCancel={() => this.setState({ showAddManager: false })}
            footer={[
              <button
                className="btn"
                onClick={() => this.setState({ showAddManager: false })}
                style={{ border: "1px solid rgba(0,0,0,0.2)" }}
              >
                Return
              </button>,
              <button
                className="btn"
                onClick={() => this.addManager()}
                style={{ backgroundColor: "#be9e55", color: "white" }}
              >
                Submit
              </button>
            ]}
          >
            <label htmlFor="" className="mt-2">
              First name
            </label>
            <Input value={this.state.editFirstName} onChange={e => this.setState({ editFirstName: e.target.value })} />
            <label htmlFor="" className="mt-2">
              Last name
            </label>
            <Input value={this.state.editLastName} onChange={e => this.setState({ editLastName: e.target.value })} />
            <label htmlFor="" className="mt-2">
              Email
            </label>
            <Input value={this.state.editEmail} onChange={e => this.setState({ editEmail: e.target.value })} />
            <label htmlFor="" className="mt-2">
              Password
            </label>
            <Input.Password value={this.state.editPassword} onChange={e => this.setState({ editPassword: e.target.value })} />
          </Modal>
        </div>
      );
    } else if (this.state.users === "" && this.state.error === false) {
      return (
        <div>
          <p className="pl-2 mt-5">
            Loading users <Icon className="ml-3" type="loading" />
          </p>
        </div>
      );
    } else if (this.state.error === true) {
      return (
        <div>
          <p className="p-2">Fetch error. Wait a couple minutes and try again. </p>
        </div>
      );
    }
  }
}
