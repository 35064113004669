import React, { Component } from "react";
import StellaRosaLogo from "./Images/stellarossa_logo.png";
import { Link, Redirect } from "react-router-dom";
import Footer from "./Footer";
import circle from "./Images/circle.png";

export default class ResetSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: 5,
      redirect: false
    };
  }

  componentDidMount() {
    this.redirectPage();
  }

  redirectPage() {
    setInterval(() => {
      let seconds = this.state.seconds;
      if (seconds > 1) {
        this.setState({
          seconds: this.state.seconds - 1
        });
      } else if (seconds === 1) {
        this.setState({
          redirect: true
        });
      }
    }, 1000);
  }

  render() {
    return (
      <div style={{ height: "100vh" }}>
        <div className="container">
          <div className="row" style={{ paddingTop: "15vh" }}>
            <div className="col-lg-2" />
            <div
              className="col-lg-8 pb-2"
              style={{
                textAlign: "center",
                borderRadius: "6px",
                boxShadow: "0px 0px 14px -5px rgba(0,0,0,0.41)"
              }}
            >
              <div
                className="row"
                style={{ borderBottom: "6px solid #be9e55" }}
              >
                <div
                  className="col-lg-12 py-5"
                  style={{
                    backgroundColor: "black"
                  }}
                >
                  <img
                    src={StellaRosaLogo}
                    alt="Stella-Rosa Logo"
                    width={400}
                    style={{ textAlign: "center" }}
                  />
                </div>
              </div>
              <div
                className="row pt-3 pb-4 mt-4 mb-1"
                style={{ backgroundColor: "white" }}
              >
                <div className="col-lg-2" />
                <div className="col-lg-8 my-3">
                  <p style={{ fontSize: 22 }}>
                    Your password has been successfully setup. To publish it,
                    make it active from news list.
                  </p>
                </div>
                <div className="col-lg-2" />
                <div className="col-lg-12 mt-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <p>
                        <Link style={{ fontSize: 15, color: "#c0a15a" }} to="/">
                          You can login now
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6">
                      <p
                        style={{
                          borderBottom: "1px solid rgba(0,0,0,0.2)",
                          fontSize: 15.5
                        }}
                      >
                        or wait for redirection to the login screen in{" "}
                        {this.state.seconds} sec.
                      </p>
                    </div>
                    <div className="col-lg-3" />
                  </div>
                  <div className="row my-4" style={{ textAlign: "center" }}>
                    <div className="col-lg-4" />
                    <div className="col-lg-4">
                      <img src={circle} alt="" style={{ width: 200 }} />
                    </div>
                    <div className="col-lg-4" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2" />
          </div>
        </div>
        {this.state.redirect === true ? <Redirect to="/" /> : null}
        <Footer />
      </div>
    );
  }
}
