import React, { Component } from "react";

export default class Carousel extends Component {
  render() {
    return (
      <div>
        <div className="col-lg-12 py-4 pl-5">
          <p style={{ fontSize: 25 }}>Carousel</p>
        </div>
        <div
            style={{ width: "96%", margin: "auto", height: "45rem" }}
            className="pb-3 pt-2 shadow"
          >
          <div className="col-lg-12 py-2 border-bottom">
            <div className="row">
              <div className="col-lg-3 pl-5">
                Filter 1
              </div>
              <div className="col-lg-3">
                Filter 2
              </div>
              <div className="col-lg-3">
                Filter 3
              </div>
              <div className="col-lg-3">
                Filter 4
              </div>
            </div>
          </div>
          </div>
      </div>
    );
  }
}
