import React, { Component } from "react";
//import Footer from "./Footer";
import StellaRosaSidebarLogo from "./Images/stellarosa-sidebar.png";
import LogOut2 from "./Images/logout2.png";
import NewsList from "./NewsList";
import { Link } from "react-router-dom";
import Users from "./Users";
import { FaRegUser, FaRegNewspaper, FaWpforms } from "react-icons/fa";
import { MdPeopleOutline } from "react-icons/md";
import { Icon, Modal /*, Input */ } from "antd";
import UsersTwo from "./UsersTwo";
import Carousel from "./Carousel";

export default class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showed: "usersTwo",
      password1: "",
      password2: "",
    };
  }

  changeToWW() {
    this.setState({
      showed: "ww",
    });
  }

  changeToSp() {
    this.setState({
      showed: "sp",
    });
  }

  changeToNews() {
    this.setState({
      showed: "news",
    });
  }

  changeToUsers() {
    this.setState({
      showed: "users",
    });
  }

  changeToUsersTwo() {
    this.setState({
      showed: "usersTwo",
    });
  }

  changeToCarousel() {
    this.setState({
      showed: "carousel"
    })
  }

  password(e) {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  }

  async resetPassword() {
    await fetch(
      process.env.REACT_APP_BACKEND_HOST + `/1.0/auth/admin/password-reset`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          email: this.props.location.state.email,
        }),
      }
    )
      .then((x) => x.json())
      .then(() => {
        this.setState({
          resetPasswordSuccess: true,
        });
      })
      .catch((err) => console.log(err));
  }

  render() {
    if (this.props.location.state !== undefined) {
      return (
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-1 py-3"
              style={{
                backgroundColor: "black",
                height: "100%",
                position: "fixed",
                zIndex: 1,
                overflow: "auto",
              }}
            >
              <img src={StellaRosaSidebarLogo} alt="" width="100%" />
              <div className="row mt-5">
                <div
                  className="col-lg-12 py-4"
                  style={
                    this.state.showed === "usersTwo"
                      ? {
                          textAlign: "center",
                          color: "white",
                          backgroundColor: "#c0a15a",
                          cursor: "pointer",
                        }
                      : {
                          textAlign: "center",
                          color: "#c0a15a",
                          cursor: "pointer",
                        }
                  }
                  onClick={() => this.changeToUsersTwo()}
                >
                  <FaWpforms size="2em" />
                  <p>Registred Users</p>
                </div>
                <div
                  className="col-lg-12 py-4"
                  style={
                    this.state.showed === "news"
                      ? {
                          textAlign: "center",
                          color: "white",
                          backgroundColor: "#c0a15a",
                          cursor: "pointer",
                        }
                      : {
                          textAlign: "center",
                          color: "#c0a15a",
                          cursor: "pointer",
                        }
                  }
                  onClick={() => this.changeToNews()}
                >
                  <FaRegNewspaper size="2em" />
                  <p style={{ fontSize: 16 }}>News</p>
                </div>
                <div
                  className="col-lg-12 py-4"
                  style={
                    this.state.showed === "users"
                      ? {
                          textAlign: "center",
                          color: "white",
                          backgroundColor: "#c0a15a",
                          cursor: "pointer",
                        }
                      : {
                          textAlign: "center",
                          color: "#c0a15a",
                          cursor: "pointer",
                        }
                  }
                  onClick={() => this.changeToUsers()}
                >
                  <MdPeopleOutline size="2em" />
                  <p>User Management</p>
                </div>
                <div
                  className="col-lg-12 py-4"
                  style={
                    this.state.showed === "carousel"
                      ? {
                          textAlign: "center",
                          color: "white",
                          backgroundColor: "#c0a15a",
                          cursor: "pointer",
                        }
                      : {
                          textAlign: "center",
                          color: "#c0a15a",
                          cursor: "pointer",
                        }
                  }
                  onClick={() => this.changeToCarousel()}
                >
                  <p>Carousel</p>
                </div>
              </div>
            </div>
            <div className="col-lg-11" style={{ marginLeft: "8%" }}>
              <div className="row">
                <div className="col-lg-12 shadow-sm py-4">
                  <div className="row">
                    <div className="col-lg-6" />
                    <div
                      className="col-lg-3"
                      style={{ color: "#c0a15a", textAlign: "right" }}
                    >
                      <FaRegUser /> {this.props.location.state.email}
                    </div>
                    <div
                      className="col-lg-2"
                      style={{
                        textAlign: "center",
                        color: "#c0a15a",
                      }}
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.setState({ modalChangePassword: true })
                        }
                      >
                        <Icon type="redo" className="mr-2" rotate={180} />
                        change password
                      </span>
                      <Modal
                        centered={true}
                        title={
                          <p style={{ fontWeight: "bold" }}>
                            Reset the password
                          </p>
                        }
                        visible={this.state.modalChangePassword}
                        onOk={() =>
                          this.setState({
                            modalChangePassword: false,
                            resetPasswordSuccess: false,
                          })
                        }
                        onCancel={() =>
                          this.setState({
                            modalChangePassword: false,
                            resetPasswordSuccess: false,
                          })
                        }
                        footer={[
                          <button
                            className="btn"
                            onClick={() =>
                              this.setState({
                                modalChangePassword: false,
                                resetPasswordSuccess: false,
                              })
                            }
                            style={{ border: "1px solid rgba(0,0,0,0.2)" }}
                          >
                            No
                          </button>,
                          <button
                            className="btn"
                            onClick={() => this.resetPassword()}
                            style={{
                              backgroundColor: "#be9e55",
                              color: "white",
                            }}
                          >
                            Yes
                          </button>,
                        ]}
                      >
                        <div
                          className="py-5"
                          style={{
                            textAlign: "center",
                            fontSize: 18,
                            fontWeight: "bold",
                          }}
                        >
                          Are you sure you want to reset the password for "
                          {this.props.location.state.email}"?
                        </div>
                        {this.state.resetPasswordSuccess ? (
                          <div
                            className="alert alert-success"
                            style={{ textAlign: "center" }}
                          >
                            We've send you a email with instructions to reset
                            password
                          </div>
                        ) : null}
                      </Modal>
                    </div>
                    <div
                      className="col-lg-1"
                      style={{ textAlign: "center", color: "#c0a15a" }}
                    >
                      <Link
                        style={{ color: "#c0a15a", textDecoration: "none" }}
                        to="/"
                      >
                        {" "}
                        <img src={LogOut2} alt="" width={20} /> log out{" "}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  {this.state.showed === "news" ? (
                    <NewsList
                      token={this.props.location.state.token}
                      uid={this.props.location.state.uid}
                    />
                  ) : null}
                  {this.state.showed === "users" ? (
                    <Users
                      token={this.props.location.state.token}
                      uid={this.props.location.state.uid}
                    />
                  ) : null}
                  {this.state.showed === "usersTwo" ? (
                    <UsersTwo token={this.props.location.state.token} />
                  ) : null}
                  {this.state.showed === "carousel" ? (
                    <Carousel
                      token={this.props.location.state.token}
                      uid={this.props.location.state.uid}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/*<Footer />*/}
        </div>
      );
    } else {
      return (
        <div>
          <p className="pl-2 py-3">
            You aren't logged in,{" "}
            <a href="https://backend.stellarosawines.com/">
              click this to log in.
            </a>
          </p>
        </div>
      );
    }
  }
}
