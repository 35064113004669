import React, { Component } from "react";
import StellaRosaLogo from "./Images/stellarossa_logo.png";
import { Link } from "react-router-dom";
import Footer from "./Footer";

export default class PasswordSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.location.state.email,
      password1: "",
      password2: ""
    };
  }

  setNewPassword() {}

  onChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <div style={{ height: "100vh" }}>
        <div className="container">
          <div className="row" style={{ paddingTop: "15vh" }}>
            <div className="col-lg-2" />
            <div
              className="col-lg-8 pb-2"
              style={{
                textAlign: "center",
                borderRadius: "6px",
                boxShadow: "0px 0px 14px -5px rgba(0,0,0,0.41)"
              }}
            >
              <div
                className="row"
                style={{ borderBottom: "6px solid #be9e55" }}
              >
                <div
                  className="col-lg-12 py-5"
                  style={{
                    backgroundColor: "black"
                  }}
                >
                  <img
                    src={StellaRosaLogo}
                    alt="Stella-Rosa Logo"
                    width={400}
                    style={{ textAlign: "center" }}
                  />
                </div>
              </div>
              <div
                className="row pt-3 pb-4 mt-4 mb-1"
                style={{ backgroundColor: "white" }}
              >
                <div className="col-lg-1" />
                <div className="col-lg-10 my-3">
                  <p style={{ fontSize: 22 }}>
                    {this.state.password1.length > 0 ||
                    this.state.password2.length
                      ? "Please make sure both passwords match."
                      : "Please setup your password."}
                  </p>
                </div>
                <div className="col-lg-1" />
                <div className="col-lg-12 mt-4">
                  <div className="row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6">
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={this.state.email}
                        onChange={e => this.email(e)}
                        style={{
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                          width: "100%",
                          textAlign: "center",
                          color: "#be9e55"
                        }}
                        className="py-2"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-3" />
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6">
                      <input
                        type="password"
                        name="password1"
                        placeholder="Password"
                        className="py-2 my-2"
                        style={{
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                          width: "100%",
                          color: "#be9e55"
                        }}
                        onChange={e => this.onChange(e)}
                      />
                    </div>
                    <div className="col-lg-3" />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6">
                      <input
                        type="password"
                        name="password2"
                        placeholder="Confirm Password"
                        className="py-2 my-2"
                        style={{
                          border: "none",
                          width: "100%",
                          color: "#be9e55"
                        }}
                        onChange={e => this.onChange(e)}
                      />
                    </div>
                    <div className="col-lg-3" />
                  </div>
                </div>
              </div>
              <div className="row my-4">
                <div className="col-lg-12">
                  <Link
                    to={{
                      pathname: "/resetsuccess",
                      state: { email: this.state.email }
                    }}
                    style={{ color: "white" }}
                  >
                    {" "}
                    <button
                      type="button"
                      className="btn px-5 py-3 rounded-pill"
                      style={
                        this.state.email.length > 0 &&
                        this.state.recaptcha !== false
                          ? { backgroundColor: "#c10230", color: "white" }
                          : {
                              backgroundColor: "#c4c5d6",
                              color: "white"
                            }
                      }
                      disabled={
                        this.state.email.length > 0
                          ?
                            false
                          : true
                      }
                      onClick={() => this.setNewPassword()}
                    >
                      {" "}
                      Submit
                    </button>
                  </Link>
                </div>
              </div>
              <div className="row my-2">
                <div className="col-lg-12">
                  <Link to="/" style={{ color: "#be9e55" }}>
                    Back
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-2" />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
