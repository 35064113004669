import React from "react";

export default function Footer() {
  let a = new Date();
  return (
    <footer
      style={{
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        backgroundColor: "white",
        color: "#c0a15a",
        zIndex: 2
      }}
      className="py-3 pl-4 shadow-lg"
    >
      {a.getFullYear()} &copy; Talkin' Things
    </footer>
  );
}
