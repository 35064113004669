import React, { Component } from "react";
import { Icon, Modal, Input, Select, message } from "antd";

export default class User extends Component {
  state = {
    uid: this.props.uid,
    showActivateModal: false,
    showDeactivateModal: false,
    token: this.props.token,
    deactivated: "",
    activated: "",
    editUser: false,
    firstName: this.props.firstName,
    lastName: this.props.lastName,
    role: this.props.role,
    email: this.props.email,
    editFirstName: this.props.firstName,
    editLastName: this.props.lastName,
    editRole: this.props.role,
    editEmail: this.props.email,
    editStatus: "",
    active: this.props.active
  };

  toggleActivateModal() {
    this.setState({
      showActivateModal: !this.state.showActivateModal,
      deactivated: "",
      activated: ""
    });
  }

  toggleDeactivateModal() {
    this.setState({
      showDeactivateModal: !this.state.showDeactivateModal,
      deactivated: "",
      activated: ""
    });
  }

  async deactivateUser() {
    await fetch(
      process.env.REACT_APP_BACKEND_HOST + `/1.0/auth/admin/users/${this.state.uid}/deactivate`,
      {
        method: "get",
        headers: {
          Authorization: `Bearer ${this.state.token}`
        }
      }
    )
      .then(resp => resp.json())
      .then(x => {
        console.log(x)
        this.setState({
          deactivated: true
        });
      })
      .catch(err => {
        console.log(err);
        /*this.setState({
          deactivated: false
        });*/
        this.setState({
          deactivated: true
        })
      });
  }

  async activateUser() {
    await fetch(
      process.env.REACT_APP_BACKEND_HOST + `/1.0/auth/admin/users/${this.state.uid}/activate`,
      {
        method: "get",
        headers: {
          Authorization: `Bearer ${this.state.token}`
        }
      }
    )
      .then(resp => resp.json())
      .then(x => {
        console.log(x)
        this.setState({
          activated: true
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({
          activated: false
        });
      });
  }

  async change() {
    let body = JSON.stringify({
      email: this.state.editEmail,
      first_name: this.state.editFirstName,
      last_name: this.state.editLastName,
      role: this.state.editRole
    })
    await fetch(
      process.env.REACT_APP_BACKEND_HOST + `/1.0/auth/admin/users/${this.state.uid}`
      , {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${this.state.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: body
    }).then(x => x.json()).then(resp => {
      message.success("Changed successfully")
    }
    ).catch(err => {
      console.error(err)
      message.error("Something didn't work")
      this.setState({
        editStatus: false
      })
    })
    this.props.sendData("Worked")
  }

  render() {
    const { Option } = Select
    let fontSize = { fontSize: 15 };
    return (
      <tr style={{ textAlign: "left" }}>
        <td className="py-3 pl-3" style={fontSize}>
          {this.props.firstName !== undefined && this.props.lastName !== undefined ? `${this.props.firstName} ${this.props.lastName}` : "Unknown"}
        </td>
        <td className="py-3" style={fontSize}>
          {this.props.email}
        </td>
        <td className="py-3" style={fontSize}>
          {this.props.active === true ? (
            <span
              className="badge badge-pill"
              style={{
                backgroundColor: "#be9e55",
                color: "white",
                fontSize: 14,
                fontWeight: "normal"
              }}
            >
              Active
            </span>
          ) : null}
          {this.props.active === false ? (
            <span
              className="badge badge-pill"
              style={{
                backgroundColor: "#c4c5d6",
                color: "white",
                fontSize: 14,
                fontWeight: "normal"
              }}
            >
              Inactive
            </span>
          ) : null}
          {this.props.active === undefined ? (
            <span style={{ opacity: 0.6 }}>Unknown</span>
          ) : null}
        </td>
        <td>
          <Icon
            type="close-square"
            style={
              this.props.active === true
                ? { cursor: "pointer" }
                : { opacity: 0.4 }
            }
            onClick={
              this.props.active === true
                ? () => this.toggleDeactivateModal()
                : null
            }
          />
          <Icon
            className="ml-2"
            type="check-square"
            style={
              this.props.active === false
                ? { cursor: "pointer" }
                : { opacity: 0.4 }
            }
            onClick={
              this.props.active === false
                ? () => this.toggleActivateModal()
                : null
            }
          />
          <Icon
            className="ml-2"
            type="form"
            style={{ cursor: "pointer" }}
            onClick={() => this.setState({ editUser: true })}
          />
        </td>
        {/*
        <td className="py-3" style={fontSize}>
          {this.props.created}{" "}
          <span className="pl-1" style={{ fontSize: 12 }}>
            {this.props.createdTime}
          </span>
        </td>
        <td className="py-3" style={fontSize}>
          {this.props.lastActive}{" "}
          <span className="pl-1" style={{ fontSize: 12 }}>
            {this.props.lastActiveTime}
          </span>
        </td>
        */}
        <Modal
          centered={true}
          title={
            <p style={{ fontWeight: "bold" }}>
              User Management - Activate User
            </p>
          }
          visible={this.state.showActivateModal}
          onOk={() => this.toggleActivateModal()}
          onCancel={() => this.toggleActivateModal()}
          footer={[
            <button
              className="btn"
              onClick={() => this.toggleActivateModal()}
              style={{ border: "1px solid rgba(0,0,0,0.2)" }}
            >
              No
            </button>,
            <button
              className="btn"
              onClick={() => this.activateUser()}
              style={{ backgroundColor: "#be9e55", color: "white" }}
            >
              Yes
            </button>
          ]}
        >
          <p
            style={{
              fontSize: 18,
              textAlign: "center",
              marginBottom: 80,
              marginTop: 25,
              fontWeight: 600
            }}
          >
            Are you sure you want to activate {this.props.firstName} {this.props.lastName}'s account?
          </p>
          {this.state.activated === true ? (
            <div className="alert alert-success">
              <p>Activated the user account</p>
            </div>
          ) : null}
          {this.state.activated === false ? (
            <div className="alert alert-danger">
              <p>Something went wrong, check console and try again</p>
            </div>
          ) : null}
        </Modal>
        <Modal
          centered={true}
          title={
            <p style={{ fontWeight: "bold" }}>
              User Management - Deactivate User
            </p>
          }
          visible={this.state.showDeactivateModal}
          onOk={() => this.toggleDeactivateModal()}
          onCancel={() => this.toggleDeactivateModal()}
          footer={[
            <button
              className="btn"
              onClick={() => this.toggleDeactivateModal()}
              style={{ border: "1px solid rgba(0,0,0,0.2)" }}
            >
              No
            </button>,
            <button
              className="btn"
              onClick={() => this.deactivateUser()}
              style={{ backgroundColor: "#be9e55", color: "white" }}
            >
              Yes
            </button>
          ]}
        >
          <p
            style={{
              fontSize: 18,
              textAlign: "center",
              marginBottom: 80,
              marginTop: 25,
              fontWeight: 600
            }}
          >
            Are you sure you want to deactivate {this.props.firstName} {this.props.lastName}'s account?
          </p>
          {this.state.deactivated === true ? (
            <div className="alert alert-success">
              <p>Deactivated the user account</p>
            </div>
          ) : null}
          {this.state.deactivated === false ? (
            <div className="alert alert-danger">
              <p>Something went wrong, check console and try again</p>
            </div>
          ) : null}
        </Modal>
        <Modal
          centered={true}
          title={
            <p style={{ fontWeight: "bold" }}>Edit profile</p>
          }
          visible={this.state.editUser}
          onOk={() => this.setState({ editUser: false })}
          onCancel={() => this.setState({ editUser: false })}
          footer={[
            <button
              className="btn"
              onClick={() => this.setState({ editUser: false })}
              style={{ border: "1px solid rgba(0,0,0,0.2)" }}
            >
              Return
              </button>,
            <button
              className="btn"
              onClick={() => this.change()}
              style={{ backgroundColor: "#be9e55", color: "white" }}
            >
              Submit
              </button>
          ]}
        >
          <label htmlFor="" className="mt-2">
            First Name
            </label>
          <Input
            value={this.state.editFirstName}
            onChange={e => this.setState({ editFirstName: e.target.value })}
          />
          <label htmlFor="" className="mt-1">
            Last Name
            </label>
          <Input
            value={this.state.editLastName}
            onChange={e => this.setState({ editLastName: e.target.value })}
          />
          <label htmlFor="" className="mt-1">
            Email
            </label>
          <Input
            value={this.state.editEmail}
            onChange={e => this.setState({ editEmail: e.target.value })}
          />
          <label htmlFor="">Role</label> <br />
          <Select
            placeholder="Change role"
            defaultValue={this.state.editRole}
            style={{ width: "100%" }}
            onChange={e => this.setState({ editRole: e })}
            value={this.state.editRole}
          >
            <Option value="admin">Admin</Option>
            <Option value="regular">Regular</Option>
          </Select>
        </Modal>
      </tr>
    );
  }
}
