import React, { Component } from "react";
import { DatePicker, Icon, Empty } from "antd";
import UserTwo from "./UserTwo";
import { Pagination } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import { FaAngleDown } from "react-icons/fa";
//import { Redirect } from "react-router-dom";

export default class UsersTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      usersPerPage: 10,
      prev: true,
      next: true,
      first: true,
      last: true,
      ellipsis: false,
      boundaryLinks: true,
      dateFrom: null,
      dateTo: null,
      sortBy: "email",
      error: false
    };
    this.changeNumber = this.changeNumber.bind(this);
  }

  async fetchData() {
    await fetch(
      process.env.REACT_APP_BACKEND_HOST + '/1.0/auth/admin/users?role=mobile',
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${this.props.token}`
        }
      }
    )
      .then(resp => resp.json())
      .then(x => {
        if (x.status === 401 || x.status === 500) {
          this.setState({
            error: true
          })
        }
        this.setState({
          fetchedData: x
        });
      })
      .catch(err => {
        this.setState({
          error: true
        });
      });
  }

  componentDidMount() {
    this.fetchData();
    setInterval(() => this.fetchData(), 30000);
  }

  changeNumber(eventKey) {
    this.setState({
      currentPage: eventKey
    });
  }

  downloadCSV(data) {
    let csv = "Name, Email, Zip Code, Gender, Registred Date\n";
    data.forEach(function (row) {
      csv += `${row.props.first_name} ${row.props.last_name}`;
      csv += `, `;
      csv += `${row.props.email}`;
      csv += `, `;
      csv += `${row.props.zip_code}`;
      csv += `, `;
      csv += `${row.props.gender}`;
      csv += `, `;
      csv += `${row.props.reg_date[0]}${row.props.reg_date[1]}${row.props.reg_date[2]}${row.props.reg_date[3]}${row.props.reg_date[4]}${row.props.reg_date[5]}${row.props.reg_date[6]}${row.props.reg_date[7]}${row.props.reg_date[8]}${row.props.reg_date[9]}`;
      csv += "\n";
    });
    let hiddenElement = document.createElement("a");
    document.body.appendChild(hiddenElement);
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_self";
    hiddenElement.download = "Registred Users.csv";
    hiddenElement.click();
  }

  dateFrom(e) {
    this.setState({
      dateFrom: e,
      sortBy: "changedDate",
      currentPage: 1
    });
  }

  dateTo(e) {
    this.setState({
      dateTo: e,
      sortBy: "changedDate",
      currentPage: 1
    });
  }

  sortEmail() {
    if (this.state.sortBy === "email") {
      this.setState({
        sortBy: "emailReversed",
        currentPage: 1
      });
    } else {
      this.setState({
        sortBy: "email",
        currentPage: 1
      });
    }
  }

  sortDate() {
    if (this.state.sortBy === "date") {
      this.setState({
        sortBy: "dateReversed",
        currentPage: 1
      });
    } else {
      this.setState({
        sortBy: "date",
        currentPage: 1
      });
    }
  }

  render() {
    let _ = require("lodash");
    if (this.state.fetchedData !== undefined && this.state.error === false) {
      let users = this.state.fetchedData.map(x => {
        return (
          <UserTwo
            first_name={x.first_name}
            last_name={x.last_name}
            email={x.email}
            zip_code={x.zip_code}
            gender={x.gender}
            reg_date={x.created_at}
          />
        );
      });

      const { currentPage, usersPerPage } = this.state;

      const indexOfLast = currentPage * usersPerPage;
      const indexOfFirst = indexOfLast - usersPerPage;

      let filtered = users.filter(x => {
        const { dateTo, dateFrom } = this.state;
        let stringedDate = `${x.props.reg_date[0]}${x.props.reg_date[1]}${x.props.reg_date[2]}${x.props.reg_date[3]}${x.props.reg_date[4]}${x.props.reg_date[5]}${x.props.reg_date[6]}${x.props.reg_date[7]}${x.props.reg_date[8]}${x.props.reg_date[9]}`;
        let date = new Date(stringedDate);
        if (dateTo !== null && dateFrom === null) {
          return date < dateTo;
        } else if (dateTo === null && dateFrom !== null) {
          return date > dateFrom;
        } else if (dateFrom !== null && dateTo !== null) {
          return date > dateFrom && date < dateTo;
        } else {
          return x;
        }
      });

      filtered = _.sortBy(filtered, function (o) {
        return o.props.email;
      });

      let email = _.sortBy(filtered, function (o) {
        return o.props.email;
      });

      let date = _.sortBy(filtered, function (o) {
        return o.props.reg_date;
      });

      let usersInLast7Days = users.filter(x => {
        let stringedDate = `${x.props.reg_date[0]}${x.props.reg_date[1]}${x.props.reg_date[2]}${x.props.reg_date[3]}${x.props.reg_date[4]}${x.props.reg_date[5]}${x.props.reg_date[6]}${x.props.reg_date[7]}${x.props.reg_date[8]}${x.props.reg_date[9]}`;
        let date = new Date(stringedDate);
        let dateToday = new Date();
        let date7DaysAgo = dateToday.setDate(dateToday.getDate() - 7);
        return date > date7DaysAgo;
      });

      let usersInLast30Days = users.filter(x => {
        let stringedDate = `${x.props.reg_date[0]}${x.props.reg_date[1]}${x.props.reg_date[2]}${x.props.reg_date[3]}${x.props.reg_date[4]}${x.props.reg_date[5]}${x.props.reg_date[6]}${x.props.reg_date[7]}${x.props.reg_date[8]}${x.props.reg_date[9]}`;
        let date = new Date(stringedDate);
        let dateToday = new Date();
        let date30DaysAgo = dateToday.setDate(dateToday.getDate() - 30);
        return date > date30DaysAgo;
      });

      const p = filtered.slice(indexOfFirst, indexOfLast);

      let pageNumbers = [];
      for (let i = 1; i <= Math.ceil(filtered.length / usersPerPage); i++) {
        pageNumbers.push(i);
      }

      let renderPageNumbers = pageNumbers.map(number => {
        return (
          <li key={number} id={number}>
            {number}
          </li>
        );
      });

      return (
        <div className="row">
          <div className="col-lg-12 py-4 pl-5">
            <div className="row">
              <div className="col-lg-3">
                {" "}
                <p style={{ fontSize: 25 }}>Registred Users</p>
              </div>
              <div className="col-lg-3">
                <p
                  style={{
                    fontSize: 13,
                    position: "relative",
                    top: "10px",
                    opacity: "0.7"
                  }}
                >
                  Registred users in total: {users.length}
                </p>
              </div>
              <div className="col-lg-3">
                <p
                  style={{
                    fontSize: 13,
                    position: "relative",
                    top: "10px",
                    opacity: "0.7"
                  }}
                >
                  Registred users in last 7 days: {usersInLast7Days.length}
                </p>
              </div>
              <div className="col-lg-3">
                <p
                  style={{
                    fontSize: 13,
                    position: "relative",
                    top: "10px",
                    opacity: "0.7"
                  }}
                >
                  Registred users in last 30 days: {usersInLast30Days.length}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{ width: "96%", margin: "auto", height: "45rem" }}
            className="pb-3 pt-2 shadow"
          >
            <div className="col-lg-12 py-2 border-bottom">
              <div className="row">
                <div className="col-lg-3 pl-5">
                  <DatePicker
                    className="pl-2"
                    placeholder="Date from"
                    style={{ width: 250, color: "black" }}
                    onChange={e => this.dateFrom(e)}
                    format="MM/DD/YYYY"
                  />
                </div>
                <div className="col-lg-3">
                  <DatePicker
                    className="pl-2"
                    placeholder="Date To"
                    style={{ width: 250, color: "black" }}
                    onChange={e => this.dateTo(e)}
                    format="MM/DD/YYYY"
                  />
                </div>
                <div className="col-lg-6"></div>
              </div>
            </div>
            <div className="col-lg-12 mt-4">
              <div style={{ width: "98%", margin: "auto" }}>
                <table className="table-striped" style={{ width: "100%" }}>
                  <thead style={{ backgroundColor: "#f4f5f8" }}>
                    <tr>
                      <th
                        className="py-3 pl-3"
                        style={{ textAlign: "left", width: "400px" }}
                      >
                        <span style={{ cursor: "pointer" }}>Name</span>
                      </th>
                      <th style={{ textAlign: "left", width: "400px" }}>
                        <button
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#f0f0f0"
                          }}
                          onClick={() => this.sortEmail()}
                        >
                          Email{" "}
                          <FaAngleDown
                            style={
                              this.state.sortBy === "emailReversed"
                                ? { transform: "rotate(180deg)" }
                                : null
                            }
                          />
                        </button>
                      </th>
                      <th style={{ textAlign: "left", width: "200px" }}>
                        <span style={{ cursor: "pointer" }}>Zip Code</span>
                      </th>
                      <th style={{ textAlign: "left", width: "200px" }}>
                        <span style={{ cursor: "pointer" }}>Gender</span>
                      </th>
                      <th style={{ textAlign: "left", width: "200px" }}>
                        <button
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#f0f0f0"
                          }}
                          onClick={() => this.sortDate()}
                        >
                          Reg Date{" "}
                          <FaAngleDown
                            style={
                              this.state.sortBy === "dateReversed"
                                ? { transform: "rotate(180deg)" }
                                : null
                            }
                          />
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.sortBy === "email"
                      ? email.slice(indexOfFirst, indexOfLast)
                      : null}
                    {this.state.sortBy === "date"
                      ? date.slice(indexOfFirst, indexOfLast)
                      : null}
                    {this.state.sortBy === "emailReversed"
                      ? email.reverse().slice(indexOfFirst, indexOfLast)
                      : null}
                    {this.state.sortBy === "dateReversed"
                      ? date.reverse().slice(indexOfFirst, indexOfLast)
                      : null}
                    {this.state.sortBy === "changedDate" ? p : null}
                  </tbody>
                </table>
                {p.length > 0 ? null : <Empty className="mt-5" />}
                <div className="col-lg-12 pt-3" style={{ textAlign: "center" }}>
                  <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4">
                      {renderPageNumbers.length < 1 ? null : (
                        <Pagination
                          /*{...this.state}
                          pages={renderPageNumbers.length}
                          maxButtons={5}
                          activePage={this.state.currentPage}
                        */
                          {...this.state}
                          pages={renderPageNumbers.length}
                          maxButtons={5}
                          activePage={this.state.currentPage}
                          onSelect={this.changeNumber}
                        />
                      )}
                    </div>
                    <div className="col-lg-3"></div>
                    <div
                      className="col-lg-1"
                      style={{ textAlign: "right", float: "right" }}
                    >
                      {filtered.length > 0 ? (
                        <button
                          className="btn"
                          style={{
                            backgroundColor: "#be9e55",
                            color: "white",
                            float: "right"
                          }}
                          onClick={() => this.downloadCSV(filtered)}
                        >
                          CSV
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (
      this.state.fetchedData === undefined &&
      this.state.error === false
    ) {
      return (
        <div>
          <p className="pl-2 mt-5">
            Loading registred users <Icon className="ml-3" type="loading" />
          </p>
        </div>
      );
    } else if (
      this.state.error === true
    ) {
      return (
        <div>
          <p className="p-2">Fetch error. Wait a couple minutes and try again. </p>
        </div>
      )
    }
  }
}
